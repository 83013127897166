import React, { useMemo, useState } from 'react';
import firebase from 'firebase/compat/app'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Dropzone, {useDropzone} from 'react-dropzone';
import {useRecoilState} from 'recoil';
import { imageState } from './state';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const config = {
  apiKey: 'AIzaSyDkNq_eaLKGEc0_g45Vrte-NzhIhDUCDmo',
  authDomain: 'phoz-7f0de.firebase.com',
  databaseURL: 'https://phoz-7f0de.firebaseio.com',
  projectId: 'phoz-7f0de',
  storageBucket: 'phoz-7f0de',
  messagingSenderId: '16826268580'
};

firebase.initializeApp(config)

function ImageUploader() {
  const [images, setImages] = useRecoilState(imageState);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: {'image/*': []}});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const onDrop = async (acceptedFiles) => {
    let uploaded = await Promise.all(acceptedFiles.map(async (file) => {
      console.log(file);
      const storage = getStorage();
      const storageRef = ref(storage, 'cosduck/' + file.name);

      let snapshot = await uploadBytes(storageRef, file, {contentType: file.type});
      let url = await getDownloadURL(snapshot.ref);
      console.log('Uploaded a blob or file!');
      return {name: file.name, url: url};
    }));
    setImages([...images, ...uploaded]);
  };

  return (
    <div>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({style})}>
            <input {...getInputProps()} />
            <button type="button">Add to Images</button>
          </div>
        )}
      </Dropzone>
    </div>
  );
}

export default ImageUploader;

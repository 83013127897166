import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {getDownloadURL, getStorage, deleteObject, ref} from "firebase/storage";

function ImageViewer() {
  const { imageName } = useParams();
  const navigate = useNavigate();
  const [src, setSrc] = useState('');

  useEffect(() => {
    const storage = getStorage();
    let itemRef = ref(storage, 'cosduck/' + imageName);
    getDownloadURL(itemRef).then((url) => {
      setSrc(url);
    });
  }, [])

  const handleDelete = async () => {
    const storage = getStorage();
    const desertRef = ref(storage, 'cosduck/' + imageName);

    deleteObject(desertRef).then(() => {
      navigate('/');
    }).catch((error) => {
      console.error('Error deleting image:', error);
    });
  };

  return (
    <div>
      <img src={`${src}`} alt={imageName} />
      <p>{imageName}</p>
      <button onClick={handleDelete}>Delete</button>
    </div>
  );
}

export default ImageViewer;

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import ImageUploader from "./ImageUploader";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar'
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {useRecoilState} from 'recoil';
import { imageState } from './state';

const config = {
  apiKey: 'AIzaSyDkNq_eaLKGEc0_g45Vrte-NzhIhDUCDmo',
  authDomain: 'phoz-7f0de.firebase.com',
  databaseURL: 'https://phoz-7f0de.firebaseio.com',
  projectId: 'phoz-7f0de',
  storageBucket: 'phoz-7f0de',
  messagingSenderId: '16826268580'
};

firebase.initializeApp(config)

function ImageGrid() {
  const [images, setImages] = useRecoilState(imageState);
  const [searchTerm, setSearchTerm] = useState('');
  const { folder } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const storage = getStorage();
    let listRef = ref(storage, 'cosduck');
    listAll(listRef)
      .then((res) => {
        Promise.all(res.items.map((itemRef) => {
          return getDownloadURL(itemRef).then((url) => {
            console.log(url);
            return ({name: itemRef.name, url: url});
          });
        })).then((images) => {
          setImages(images);
        });
      }).catch((error) => {
      console.log(error)
    });

  }, []);

  console.log(images);
  const filteredImages = images.filter(image => image.name.includes(searchTerm));

  return (
    <div>
      <input type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder="Search..." />
      <ImageUploader />
      <div>
        <ImageList cols={5}>
          {filteredImages.map((item) => (
            <ImageListItem key={item.url} onClick={() => navigate("/image/" + item.name)}>
              <img
                srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                alt={item.name}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.name}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.name}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
}

export default ImageGrid;
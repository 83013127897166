import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ImageGrid from './ImageGrid';
import ImageViewer from './ImageViewer';
import {RecoilRoot, atom} from 'recoil';

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ImageGrid />} />
          <Route path="/image/:imageName" element={<ImageViewer />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;